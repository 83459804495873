<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div v-if="!isLoading">
    <v-card-text>
      <v-row>
        <v-col
          v-if="(permisos.includes('contrate_cars:create') || permisos.includes('*')) && !offline"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-col
          v-if="permisos.includes('contrate_cars:create') && !offline"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            outlined
            @click="uploadLote"
          >
            <v-icon>{{ icons.mdiCloudUpload }}</v-icon>
            <span class="pl-2">{{ $t('btn.uploadLote') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          v-if="user.nivel === -1 && permisos.includes('exportation:list')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            outlined
            @click="exportar"
          >
            <v-icon>mdi-export</v-icon>
            <span class="pl-2">{{ $t('menu.export') }}</span>
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <strong>{{ $t('lbl.listContratos') }}</strong>
          <v-autocomplete
            v-model="model.rentadora_id"
            class="pt-2"
            :items="itemsRentadora"
            :search-input.sync="searchRentadora"
            hide-details
            hide-selected
            :label="$t('lbl.rental')"
            outlined
            dense
            item-text="name"
            item-value="id"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.rental') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <strong style="opacity:0;">.</strong>
          <v-autocomplete
            v-model="model.proveedor_id"
            class="pt-2"
            :items="itemsProveedor"
            :search-input.sync="searchProveedor"
            hide-details
            hide-selected
            :label="$t('lbl.proveedor')"
            outlined
            dense
            item-text="name_comercial"
            item-value="id"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.proveedor') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name_comercial"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <strong style="opacity:0;">.</strong>
          <v-text-field
            v-model="model.identificador"
            :label="$t('lbl.identificador')"
            class="pt-2"
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>

        <!--<v-col
          cols="12"
          md="2"
        >
          <strong style="opacity:0;">.</strong>
          <v-select
            v-model="model.modality"
            :items="modalities"
            :label="$t('lbl.modality')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="value"
            class="pt-2"
          ></v-select>
        </v-col>-->
      </v-row>

      <v-row>
        <!--<v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="model.tipo_contrato"
            :items="tipos_contratos"
            item-text="name"
            item-value="slug"
            :label="$t('lbl.contrato')"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="model.calcule_tarif_all_day_by_first"
            :items="precios"
            item-text="name"
            item-value="value"
            :label="$t('lbl.price')"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>-->
        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            v-model="menuFrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFromDateFormatted"
                :label="$t('lbl.from')"
                persistent-hint
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="model.from"
              no-title
              locale="es"
              :show-current="false"
              @input="
                menuFrom = false
                activeToDate()
              "
            ></v-date-picker>
          </v-menu>
          <!--<vc-date-picker
            v-model="model.from"
            outlined
            dense
            mode="date"
            :model-config="modelConfig"
            width="auto"
            @input="activeToDate"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                :label="$t('lbl.from')"
                outlined
                dense
                :value="inputValue"
                v-on="inputEvents"
              >
              </v-text-field>
            </template>
          </vc-date-picker>-->
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            v-model="menuTo"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedToDateFormatted"
                :label="$t('lbl.to')"
                persistent-hint
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="model.to"
              no-title
              locale="es"
              :show-current="false"
              :min="
                model.from
                  ? $moment(model.from)
                    .add(1, 'day')
                    .toDate()
                    .toISOString()
                    .substr(0, 10)
                  : ''
              "
              @input="menuTo = false"
            ></v-date-picker>
          </v-menu>
          <!--<vc-date-picker
            v-if="model.from !== null && model.from !== 'null' && model.from !== ''"
            v-model="model.to"
            outlined
            dense
            mode="date"
            :model-config="modelConfig"
            width="auto"
            :min-date="
              $moment(model.from)
                .add(1, 'days')
                .toDate()
            "
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                ref="dateTo"
                :label="$t('lbl.to')"
                outlined
                dense
                :value="inputValue"
                v-on="inputEvents"
              >
              </v-text-field>
            </template>
          </vc-date-picker>
          <vc-date-picker
            v-else
            v-model="model.to"
            outlined
            dense
            mode="date"
            :model-config="modelConfig"
            width="auto"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                :label="$t('lbl.to')"
                outlined
                dense
                :value="inputValue"
                v-on="inputEvents"
              >
              </v-text-field>
            </template>
          </vc-date-picker>-->
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="model.state"
            :items="states"
            :label="$t('lbl.state')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="
              model.rentadora_id ||
                model.proveedor_id ||
                model.identificador ||
                model.state ||
                model.modality ||
                model.tipo_contrato ||
                model.calcule_tarif_all_day_by_first ||
                model.from ||
                model.to
            "
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch()"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="9"
          class="text-center"
        >
          <v-btn
            :loading="loading"
            color="primary"
            @click="searchAll()"
          >
            <v-icon
              right
              dark
            >
              {{ icons.mdiMagnify }}
            </v-icon>
            <span class="pl-5">{{ $t('btn.search') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('lbl.contratos') }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.proveedor') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.rental') }}
            </th>
            <!--<th
              v-if="user.nivel <= 0"
              class="text-uppercase"
            >
              {{ $t('menu.operador') }}
            </th>-->
            <th class="text-uppercase">
              {{ $t('lbl.identificador') }}
            </th>
            <th
              class="text-uppercase"
              style="text-align: center;"
            >
              {{ $t('lbl.currency') }}
            </th>
            <!--<th class="text-uppercase">
              {{ $t('lbl.contrato') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.price') }}
            </th>-->
            <th class="text-uppercase">
              {{ $t('lbl.from') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.to') }}
            </th>
            <th class="text-uppercase text-center">
              <!--{{ $t('lbl.state') }}-->
              {{ $t('lbl.state') }}
            </th>
            <!--<th class="text-uppercase">
              {{ $t('lbl.modality') }}
            </th>-->
            <th
              v-if="
                permisos.includes('contrate_cars:list') ||
                  permisos.includes('contrate_cars:edit') ||
                  permisos.includes('contrate_cars:destroy') ||
                  permisos.includes('*')
              "
              class="text-uppercase"
              width="150px"
              style="text-align: center;"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>
              <span v-if="user.nivel <= 0">
                <span v-if="iten.operador === 1111">
                  <span v-if="iten.proveedor_id != null">
                    {{ iten.proveedor.name_comercial }}
                  </span>
                </span>
                <span v-else>
                  {{ iten.operador }}
                </span>
              </span>
              <span v-else>
                <span v-if="iten.proveedor_id != null">
                  {{ iten.proveedor.name_comercial }}
                </span>
              </span>
            </td>
            <td>
              {{ iten.rentadora.name }}
            </td>
            <!--<td v-if="user.nivel <= 0">
              {{ iten.operador }}
            </td>-->
            <td>{{ iten.identificador }}</td>
            <td style="text-align: center;">
              <span v-if="iten.currency_id">
                {{ iten.currency.code }}
              </span>
            </td>
            <!--<td>
              <span v-if="iten.tipo_contrato === 'categoria'">
                {{ $t('lbl.cat') }}
              </span>
              <span v-else>
                {{ $t('lbl.brandModel') }}
              </span>
            </td>
            <td>
              <span v-if="iten.calcule_tarif_all_day_by_first">
                {{ $t('lbl.firstDay') }}
              </span>
              <span v-else>
                {{ $t('lbl.dayByDay') }}
              </span>
            </td>-->
            <td>
              <span v-if="iten.firstDay !== null">
                {{ iten.firstDay | moment('DD/MMM/Y') }}
              </span>
            </td>
            <td>
              <span v-if="iten.lastDay !== null">
                {{ iten.lastDay | moment('DD/MMM/Y') }}
              </span>
            </td>
            <td class="text-center">
              <span v-if="iten.publico && !iten.cancelado && !iten.vencido">
                <v-tooltip
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('lbl.active') }}</span>
                </v-tooltip>
              </span>
              <span v-else-if="!iten.publico && !iten.cancelado && !iten.vencido">
                <v-tooltip
                  top
                  color="error"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error">mdi-currency-usd-off</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('lbl.stopSale') }}</span>
                </v-tooltip>
              </span>
              <span v-else-if="iten.cancelado">
                <v-tooltip
                  top
                  color="error"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('lbl.canceled') }}</span>
                </v-tooltip>
              </span>
              <span v-else-if="iten.vencido">
                <v-tooltip
                  top
                  color="error"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error">mdi-watch-vibrate-off</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('lbl.vencido') }}</span>
                </v-tooltip>
              </span>
            </td>
            <!--<td class="text-center">
              {{ iten.modality }}
            </td>-->
            <!--<td>
              <span v-if="iten.comisionable > 0">
                {{ `${iten.comisionable}%` }}
              </span>
            </td>-->
            <td
              v-if="
                permisos.includes('contrate_cars:list') ||
                  permisos.includes('contrate_cars:edit') ||
                  permisos.includes('contrate_cars:destroy') ||
                  permisos.includes('*')
              "
              style="text-align: center;"
            >
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="
                      (permisos.includes('contrate_cars:edit') || permisos.includes('*')) && iten.nivel === user.nivel
                    "
                    link
                    @click="editItem(iten)"
                  >
                    <v-list-item-title>
                      <v-btn icon>
                        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                      </v-btn>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="permisos.includes('contrate_cars:list') || permisos.includes('*')"
                    link
                    @click="seeItem(iten)"
                  >
                    <v-list-item-title>
                      <v-btn icon>
                        <v-icon>{{ icons.mdiEye }}</v-icon>
                      </v-btn>
                      <span>{{ $t('btn.see') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      (permisos.includes('contrate_cars:destroy') || permisos.includes('*')) &&
                        iten.nivel === user.nivel
                    "
                    link
                    @click="deleteContrateItem(iten)"
                  >
                    <v-list-item-title>
                      <v-btn icon>
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                      <span>{{ $t('btn.delete') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- MOSTRAR DELETE CONTRATE-->
    <v-dialog
      v-model="isDialogVisibleDeleteContrate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteContrate') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDeleteContrate = !isDialogVisibleDeleteContrate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDeleteContrate()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiEyePlus,
  mdiMenuRight,
  mdiMenuLeft,
  mdiMenuDown,
  mdiEraserVariant,
  mdiEye,
  mdiCloudUpload,
  mdiDotsVertical,
} from '@mdi/js'

export default {
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiEyePlus,
        mdiMenuRight,
        mdiMenuLeft,
        mdiMenuDown,
        mdiEraserVariant,
        mdiEye,
        mdiCloudUpload,
        mdiDotsVertical,
      },
      rentadoras: [],
      showRow: false,
      indexRow: -1,
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      isDialogVisibleDeleteContrate: false,
      item: {},
      itemId: null,
      itemPosTarifa: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),

      model: {
        rentadora_id: null,
        proveedor_id: null,
        identificador: null,
        state: null,
        tipo_contrato: null,
        calcule_tarif_all_day_by_first: null,
        from: null,
        to: null,
      },
      aerolineas: [],
      itemsAerolinea: [],
      numeros_vuelos: [],
      semanas: [],
      itemsRentadora: [],
      searchRentadora: null,
      tipoContrato: 'categoria',
      rules: {
        required: value => !!value || 'Required.',
        // eslint-disable-next-line no-restricted-globals
        numeric: value => !isNaN(value) || 'Invalid value.',
        counter: value => parseInt(value, 10) <= 100 || 'Number between 1 and 100',
      },
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
      tipos_contratos: [],
      precios: [],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      states: [],
      modalities: [],
      menuFrom: false,
      menuTo: false,
      isLoading: true,
      user: {},
      offline: false,
    }
  },
  watch: {
    searchRentadora(val) {
      if (val !== null && val.length > 1) {
        this.filterRentadora(val.toLowerCase())
      }
    },
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState({
      pricesTemporadasContrateCar: state => state.app.pricesTemporadasContrateCar,
    }),
    computedFromDateFormatted() {
      return this.model.from ? this.$moment(this.model.from).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.model.to ? this.$moment(this.model.to).format('D-MMM-YY') : ''
    },
  },
  created() {
    this.profile()
    this.getRentadoras()
    this.getProveedores()
    this.loadAll()
    this.load()

    this.tipos_contratos.push({
      name: this.$t('lbl.cat'),
      slug: 'categoria',
    })
    this.tipos_contratos.push({
      name: this.$t('lbl.brandModel'),
      slug: 'marca_modelo',
    })

    this.precios.push({
      name: this.$t('lbl.firstDay'),
      value: 1,
    })
    this.precios.push({
      name: this.$t('lbl.dayByDay'),
      value: 2,
    })

    /* this.states.push({
      name: this.$t('lbl.publico'),
      value: 'public',
    })
    this.states.push({
      name: this.$t('lbl.sinPublicar'),
      value: 'not_public',
    }) */

    this.states.push({
      name: this.$t('lbl.active'),
      value: 'active',
    })
    this.states.push({
      name: this.$t('lbl.stopSale'),
      value: 'stopSale',
    })
    this.states.push({
      name: this.$t('lbl.canceled'),
      value: 'canceled',
    })
    this.states.push({
      name: this.$t('lbl.vencido'),
      value: 'vencido',
    })

    this.modalities.push({
      name: this.$t('lbl.cupo'),
      value: 'cupo',
    })
    this.modalities.push({
      name: 'On Request',
      value: 'or',
    })
  },
  methods: {
    ...mapMutations([
      'updateCategoryContrateCar',
      'updateMarcaModeloContrateCar',
      'updateTemporadaContrateCar',
      'updatePricesTemporadasContrateCar',
      'updateSuplementoContrateCar',
    ]),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
          if (this.user.company_id) {
            if (this.user.company) {
              if (this.user.company.offline) {
                this.offline = this.user.company.offline
              }
            }
          }
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isLoading = false
        })
    },
    getRentadoras() {
      this.axios
        .get(`nom_rentadora_cars?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.rentadoras = res.data.data
        })
    },
    getProveedores() {
      this.axios
        .get('afiliados/byproduct/cars', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = res.data.data
        })
    },
    filterRentadora(v) {
      this.itemsRentadora = []
      if (v === '') {
        this.itemsRentadora = []
      } else {
        this.itemsRentadora = this.rentadoras.filter(e => e.name.toLowerCase())
      }
    },
    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },
    load() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.model,
        perfil_slug: sessionStorage.getItem('perfil'),
      }
      this.axios
        .post('contrate_cars/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            // this.items = res.data.data
            // PARA ORDERNAR BY PROVEEDOR
            this.items = res.data.data.sort((a, b) => {
              let OrderA = a.identificador
              if (this.user.nivel <= 0) {
                if (a.operador === 1111) {
                  OrderA = a.proveedor.name_comercial
                } else {
                  OrderA = a.operador
                }
              } else {
                OrderA = a.proveedor.name_comercial
              }

              let OrderB = b.identificador
              if (this.user.nivel <= 0) {
                if (b.operador === 1111) {
                  OrderB = b.proveedor.name_comercial
                } else {
                  OrderB = b.operador
                }
              } else {
                OrderB = b.proveedor.name_comercial
              }

              const x = OrderA
              const y = OrderB

              if (x > y) {
                return 1
              }
              if (x < y) {
                return -1
              }

              return 0
            })

            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
    searchAll() {
      this.pagination.current = 1
      this.load()
    },
    loadAll() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        perfil_slug: sessionStorage.getItem('perfil'),
      }
      this.axios
        .post('contrate_cars/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // console.log(res)
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.iaerolinea_idsDialogVisible
    },
    newItem() {
      sessionStorage.removeItem('cars-contrate-show')
      sessionStorage.removeItem('cars-contrate-id')
      sessionStorage.removeItem('cars-contrate-pos-tarifa')
      sessionStorage.removeItem('cars-contrate-tarifa-edit')
      this.updateSuplementoContrateCar([])
      this.$router.push({ name: 'cars-contrate-update' })
    },
    editItem(iten) {
      // this.$store.state.app.loadEscalas = true
      sessionStorage.setItem('cars-contrate-id', iten.id)
      sessionStorage.setItem('cars-contrate-tarifa-edit', true)
      sessionStorage.removeItem('cars-contrate-show')

      this.$router.push({ name: 'cars-contrate-update' })
    },
    seeItem(iten) {
      sessionStorage.setItem('cars-contrate-id', iten.id)
      sessionStorage.setItem('cars-contrate-show', true)

      this.$router.push({ name: 'cars-contrate-update' })
    },
    cloneItem(iten, posTarifa) {
      // console.log(iten)
      this.$store.state.app.loadEscalas = true
      sessionStorage.setItem('flight-contrate-id', iten.id)
      sessionStorage.setItem('flight-contrate-pos-tarifa', posTarifa)

      sessionStorage.removeItem('flight-contrate-tarifa-edit')

      sessionStorage.setItem('flight-aerolinea-id', iten.flight.aerolinea_id)
      sessionStorage.setItem('flight-numero-vuelo', iten.flight.numero_de_vuelo)

      this.$router.push({ name: 'flights-contrate-update' })
    },
    uploadLote() {
      sessionStorage.removeItem('cars-contrate-id')
      sessionStorage.removeItem('cars-contrate-clone')
      sessionStorage.removeItem('cars-contrate-show')

      // this.$router.push({ name: 'cars-contrate-upload-lote' })
    },
    exportar() {
      sessionStorage.setItem('entity-export', 'cars-contrate')

      this.$router.push({ name: 'cars-contrate-export' })
    },
    deleteItem(iten, posTarifa) {
      this.isDialogVisibleDelete = true
      this.itemId = iten.id
      this.itemPosTarifa = posTarifa
    },
    confirmDelete() {
      this.axios
        .delete(`contrate_cars/destroy-tarifa/${this.itemId}/${this.itemPosTarifa}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisibleDelete = false
      this.isDialogVisibleDeleteContrate = false
      this.item = {}
      this.itemId = null
      this.itemPosTarifa = null
      this.loading = false
      this.load()
    },
    menorPrice(tarifas, type) {
      const prices = []
      if (type === 'price_adult') {
        tarifas.forEach(element => {
          prices.push(element.price_adult)
        })
      } else if (type === 'price_child') {
        tarifas.forEach(element => {
          prices.push(element.price_child)
        })
      }

      return this.sortedArray(prices)[0]
    },
    sortedArray(arrays) {
      return arrays.sort((a, b) => a - b)
    },
    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },

    clearSearch() {
      this.model = {
        rentadora_id: null,
        proveedor_id: null,
        identificador: null,
        state: null,
        tipo_contrato: null,
        calcule_tarif_all_day_by_first: null,
        from: null,
        to: null,
      }
      this.load()
    },
    deleteContrateItem(iten) {
      this.isDialogVisibleDeleteContrate = true
      this.itemId = iten.id
    },
    confirmDeleteContrate() {
      this.loading = true
      this.axios
        .delete(`contrate_cars/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
          }
        })
        .finally(() => this.reseat())
    },

    activeToDate() {
      if (this.model.from) {
        setTimeout(() => {
          this.menuTo = true

          // this.$refs.dateTo.focus()
        }, 100)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
